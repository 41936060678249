import firebase from "firebase/compat/app"
//import { deleteUser  } from "firebase/auth"

export default {
    namespaced: true,
    state: {},
    getters: {},
    mutations: {},
    actions: {
        async fetchOrders() {
            const snapshot = await firebase.firestore().collection('orders') .orderBy('createdAt', 'desc').get()
            let products = []
            for (let i = 0; i < snapshot.docs.length; i++) {
                const product = snapshot.docs[i].data()
                product.id = snapshot.docs[i].id

                const profileSnap = await product.profileId.get()
                const profile = profileSnap.data()
                profile.id = profileSnap.id

                product.profile = profile

                products.push(product)
            }

            return products
        },
        async fetchOrder(ctx, orderId) {
            const data = await firebase.firestore().collection('orders').doc(orderId).get()
            const order = data.data()
            order.id = data.id

            //profile
            const profileData = await order.profileId.get()
            const profile = profileData.data()
            profile.id = profileData.id

            order.profile = profile

            //delivery_address
            const deliveryAddressData = await firebase.firestore().collection('delivery_addresses').where('profile_id', '==', profileData.ref).get()
            const deliveryAddress = deliveryAddressData.docs[0].data()
            deliveryAddress.id = deliveryAddressData.docs[0].id

            order.delivery_address = deliveryAddress

            //order details con products
            const orderDetails = []
            for (const orderDetail of order.products) {
                const productData = await orderDetail.productId.get()
                const product = productData.data()
                product.id = productData.id

                orderDetail.product = product

                orderDetails.push(orderDetail)

            }

            order.order_details = orderDetails

            return order
        },
        async changeOrderStatus(ctx, {id, status}) {
            await firebase.firestore().collection('orders').doc(id).update({status})
            return true
        }
        /* addUser(ctx, userData) {
             return new Promise((resolve, reject) => {
                 firebase.auth().createUserWithEmailAndPassword(userData.username, userData.password).then(() => {
                     firebase.firestore().collection('admin_users').add({username: userData.username}).then(() => {
                         resolve(true)
                     })
                 }).catch(err => {
                     reject(err)
                 })
             })
         },*/
        /*editUser(ctx, userData) {
            return new Promise((resolve, reject) => {
                //firebase.auth().up

            })
        },
        deleteUser(ctx, {id}) {
            return new Promise((resolve, reject) => {

            })
        }*/
    }
}
